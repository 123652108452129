<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 "
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 100],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
      :search="filterSearch"
      :custom-filter="filterByError"
    >
      <template slot="no-data">
        {{ $t('table.messages.no_data') }}
      </template>
      <template v-slot:footer.page-text="props">
        {{ props.pageStart }} - {{ props.pageStop }}
        {{ $t('table.headers.page-text') }}
        {{ props.itemsLength }}
      </template>
      <template v-slot:top>
        <v-container class="mt-5">
          <v-row>
            <v-col class="pb-0">
              <div>
                <span class="forth--text text-h4 font-weight-bold ">{{
                  $t('messages.browse_file')
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex ">
            <v-col cols="10">
              <div class="align-self-center mr-9">
                <v-file-input
                  v-model="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  counter
                  counter-string="a"
                  show-size
                  truncate-length="29"
                  outlined
                  prepend-icon=""
                  @click:clear="clearFile"
                >
                  <template v-slot:counter="props">
                    <div>
                      {{
                        props.props.value.match(/\d+\.*\d*/g)[0] +
                          'ファイル （合計' +
                          props.props.value.match(/\d+\.*\d*/g)[1] +
                          'B）'
                      }}
                    </div>
                  </template>
                </v-file-input>
              </div>
            </v-col>

            <v-col
              cols="2"
              class="d-flex align-end p-0"
            >
              <div class=" mb-9">
                <v-btn
                  large
                  color="primary"
                  @click="$router.go(-1)"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t('table.messages.back') }}
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex ">
            <v-col cols="3">
              <v-select
                v-model="filterSearch"
                :items="previewOptions"
                :label="$t('messages.preview_option')"
                item-text="text"
                item-value="value"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.index="props">
        {{ props.index + 1 }}
      </template>
      <template v-slot:item.customerName="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'customerName')"
        >{{ props.item.customerName }}</span>
      </template>
      <template v-slot:item.personInCharge="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'personInCharge')"
        >{{ props.item.personInCharge }}</span>
      </template>

      <template v-slot:item.personInput="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'personInput')"
        >{{ props.item.personInput }}</span>
      </template>
      <template v-slot:item.personCheck="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'personCheck')"
        >{{ props.item.personCheck }}</span>
      </template>
      <template v-slot:item.personTransfer="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'personTransfer')"
        >{{ props.item.personTransfer }}</span>
      </template>
      <template v-slot:item.deadlineAfterTransferMail="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'deadlineAfterTransferMail')"
        >{{ props.item.deadlineAfterTransferMail }}</span>
      </template>
      <template v-slot:item.companyName="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'companyName')"
        >{{ props.item.companyName }}</span>
      </template>

      <template v-slot:item.contactAddress="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'contactAddress')"
        >{{ props.item.contactAddress }}</span>
      </template>

      <template v-slot:item.payday="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'payday')"
        >{{ props.item.payday }}</span>
      </template>

      <template v-slot:footer.prepend />
    </v-data-table>
    <v-row class="d-flex justify-end mt-3">
      <v-col
        cols="3"
        class="d-flex justify-center"
      >
        <v-btn
          large
          color="info"
        >
          <span
            class="text-h5 pr-3 pl-3"
            @click="onSubmitCSV"
          >
            {{ $t('messages.importCSV') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import XLSX from 'xlsx'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'

  export default {
    name: 'CustomerImportCSV',

    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        excelFileConfig: {
          fileOffSet: 1, // defaut
          fileHeaderStart: 2,
          fileDataSkip: 0,
        },
        fileIsValid: false,
        rawData: [],
        previewOptions: [],
        filterSearch: '',
        headers: [],
        items: [],
        fileCriticalErrors: [],
        file: [],
        loading: false,
        importRules: [],
        schema: [],
      }
    },
    computed: {
      ...get('customer', ['message', 'status', 'error']),
    },
    watch: {
      status (value) {
        this.loading = value === 'loading'
        if (value === 'success') {
          this.$router.push({ path: '/customer-list', query: { success: true } })
        } else if (value === 'error') {
          this.showSnackBar('messages.server_error')
        }
      },
      file (value) {
        this.filterSearch = ''
        this.rawData = []
        this.doLoadAndPaser(value)
      },
      rawData (value) {
        this.items = this.doCheckAndPreview(
          value,
          this.schema,
          this.excelFileConfig.fileOffSet,
        )
      },
      items (value) {
        const tempCriticalErrors = []
        this.items.forEach(item => {
          // check if there is any error for required key
          if (item.errors.find(o => o.required === true)) {
            tempCriticalErrors.push(...item.errors)
          }
        })
        this.fileCriticalErrors = tempCriticalErrors
      },
      fileCriticalErrors (value) {
        this.fileIsValid = value.length === 0
      },
    },
    created () {
      const user = JSON.parse(localStorage.getItem('user'))
      const data = JSON.parse(_.get(user, 'data', {}))
      const type = _.get(data, 'type', 0)

      if (type !== 1 && type !== 2) {
        this.$router.push('/')
      }
    },
    mounted () {
      this.headers = [
        {
          text: this.$t('table.headers.remarks'),
          align: 'center',
          value: '',
          class: 'pr-0 pl-2',
          width: '6rem',
        },

        {
          text: this.$t('table.headers.index'),
          align: 'center',
          value: 'index',
          class: 'pr-0 pl-2',
          width: '8rem',
        },

        {
          text: this.$t('table.headers.personInCharge'),
          align: 'center',
          value: 'inChargedEmployee',
          class: 'pr-0 pl-2',
          width: '7rem',
        },
        {
          text: this.$t('table.headers.personInput'),
          align: 'center',
          value: 'inputEmployee',
          class: 'pr-0 pl-2',
          width: '7rem',
        },
        {
          text: this.$t('table.headers.personCheck'),
          align: 'center',
          value: 'inputCheckEmployee',
          class: 'pr-0 pl-2',
          width: '7rem',
        },
        {
          text: this.$t('table.headers.personTransfer'),
          align: 'center',
          value: 'paymentCheckEmployee',
          class: 'pr-0 pl-2',
          width: '7rem',
        },
        {
          text: this.$t('table.headers.email'),
          align: 'center',
          value: 'email',
          class: 'pr-0 pl-2',
          width: '12rem',
        },
        {
          text: this.$t('table.headers.contact_phone'),
          align: 'center',
          value: 'contact_phone',
          class: 'pr-0 pl-2',
          width: '7rem',
        },

        {
          text: this.$t('table.headers.deadlineAfterTransferMail'),
          align: 'center',
          value: 'invoiceSendDeadline',
          class: 'pr-0 pl-2',
          width: '10rem',
        },
        {
          text: this.$t('table.headers.customerName'),
          align: 'center',
          value: 'name',
          class: 'pr-0 pl-2',
          width: '7rem',
        },
        {
          text: this.$t('table.headers.companyName'),
          align: 'center',
          value: 'sendAddress',
          class: 'pr-0 pl-2',
          width: '15rem',
        },
        {
          text: this.$t('table.headers.contactAddress'),
          align: 'center',
          value: 'contact_address',
          class: 'pr-0 pl-2',
          width: '15rem',
        },
        {
          text: this.$t('table.headers.payday'),
          align: 'center',
          value: 'payday',
          class: 'pr-0 pl-2',
          width: '6rem',
        },
      ]
      this.importRules = [
        value =>
          !value || value.size < 2000000 || this.$t('messages.file_over_weight'),
      ]
      this.previewOptions = [
        { text: this.$t('messages.all'), value: 'ALL' },
        { text: this.$t('messages.only_error'), value: 'ONLY_ERROR' },
        { text: this.$t('messages.non_error'), value: 'NON_ERROR' },
      ]
      this.schema = [
        {
          key: 'name',
          headerName: '工場名',
          required: true,
        },
        {
          key: 'inChargedEmployee',
          headerName: '担当',
          required: false,
        },
        {
          key: 'inputEmployee',
          headerName: '入力',
          required: false,
        },
        {
          key: 'inputCheckEmployee',
          headerName: '入力確認',
          required: false,
        },
        {
          key: 'paymentCheckEmployee',
          headerName: '振込確認',
          required: false,
        },
        {
          key: 'invoiceSendDeadline',
          headerName: '請求書送付期限',
          required: false,
        },

        {
          key: 'sendAddress',
          headerName: '送付宛名',
          required: false,
        },
        {
          key: 'contactAddress',
          headerName: '住所',
          required: false,
        },
        {
          key: 'payday',
          headerName: '給料日',
          required: false,
        },
      ]
    },

    methods: {
      clearFile () {
        this.file = []
        this.items = []
      },
      onSubmitCSV () {
        this.fileIsValid = true
        let tempData = []
        tempData = this.items.map(item => {
          // check if there is any error for required key
          if (item.errors.find(o => o.required === true)) {
            this.fileIsValid = false
          }

          // copy item to new item
          const newItem = { ...item }

          // delete extra item
          delete newItem.errors

          // temporary set data
          newItem.employeeId = '1'
          newItem.remarks = ''
          newItem.contactPhone = ''
          newItem.isAssigned = true
          newItem.note = ''
          newItem.payday = ''
          // Re-run data convert if have
          this.schema.forEach(elSchema => {
            const key = elSchema.key

            if (
              item.errors.find(o => {
                return o.key === key
              })
            ) {
              if (!item[key]) newItem[key] = ''
              else if (elSchema.defautValues) {
                if (elSchema.defautValues[item[key]]) {
                  newItem[key] = elSchema.defautValues[item[key]]
                } else {
                  newItem[key] = elSchema.isEmptyIfError
                    ? ''
                    : elSchema.isNullIfError
                      ? null
                      : item[key]
                }
              }
              newItem[key] = null
            } else if (elSchema.formatValue) {
              newItem[key] = elSchema.formatValue(item[key])
            }
          })
          return newItem
        })
        // check if is valid
        if (this.fileIsValid) {
          this.$store.dispatch('customer/submitCSV', tempData)
        } else this.showSnackBar('messages.invalid_data')
      },
      initialize (search = {}) {},
      // Call paser function and load to Sate rawData
      doLoadAndPaser (file) {
        if (!file) return
        // set offset
        this.excelFileConfig.fileOffSet =
          this.excelFileConfig.fileOffSet +
          this.excelFileConfig.fileDataSkip +
          this.excelFileConfig.fileHeaderStart
        this.readData(file).then(response => {
          for (var i = 0; i < this.excelFileConfig.fileDataSkip; i++) {
            delete response[0][i]
          }
          // only get first sheet in File
          // required state rawData !
          this.rawData = response[0]
        })
      },
      // check and preview the array of object through a schema
      doCheckAndPreview (data, schema, offset) {
        const items = data.map((row, index) => {
          const item = {}

          // validate and tranforming raw item
          const validate = (object, schema, rowIndex) =>
            schema
              .filter(col => {
                // transform item attribute
                const headerName = col.headerName
                const key = col.key
                if (col.required && !object[headerName]) {
                  return true
                }

                item[key] = object[headerName]
                  ? object[headerName].replace(/\s+/g, ' ').trim()
                  : ''
                // remove whitespace

                if (col.validate) {
                  return !col.validate(object[headerName], object)
                }
                return false
              })
              .map(col => {
                // set return error of item attribute
                return {
                  message: `row ${rowIndex} - ${object[col.headerName]} - '${
                    col.key
                  }'' is invalid.`,
                  key: col.key,
                  required: col.required,
                }
              })

          const errors = validate(row, schema, index + offset)

          item.errors = errors
          return item
        })

        return items
      },
      readData (fileName) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.onload = e => {
            var data = e.target.result
            let returnData = []
            var workbook = XLSX.read(data, {
              type: 'binary',
            })

            workbook.SheetNames.forEach(sheetName => {
              // Here is your object
              var xlRowObject = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheetName],
                {
                  defval: '',
                  range: this.excelFileConfig.fileHeaderStart,
                  raw: false,
                },
              )
              returnData = [...returnData, xlRowObject]
            })

            resolve(returnData)
          }

          reader.onerror = function (ex) {
            reject(ex)
          }

          reader.readAsBinaryString(fileName)
        })
      },
      setHighlight (item, fieldname) {
        const error = item.errors.find(o => o.key === fieldname)
        if (error) {
          if (item.errors.length === 0) return ''
          if (error.required) {
            return 'd-flex h-100 error white--text justify-center align-center'
          }

          return 'd-flex h-100 warning white--text justify-center align-center'
        }
        return ''
      },
      filterByError (value, search, item) {
        if (search) {
          if (search === 'ALL') return true
          if (search === 'ONLY_ERROR') return item.errors.length > 0
          if (search === 'NON_ERROR') return item.errors.length === 0
        }
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
  }
</script>
